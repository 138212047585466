import { StepSyncProgress, IStepSyncProgressOptions, IStepSyncProgressJSON } from 'o365.pwa.modules.client.steps.StepSyncProgress.ts';

export interface ICleanupAppProgressJSON extends IStepSyncProgressJSON {
    cleanupHasStarted: boolean;
    cleanupHasErrors: boolean;
    cleanupHasCompleted: boolean;
}

export interface ICleanupAppProgressOptions extends IStepSyncProgressOptions {
    cleanupHasStarted?: boolean;
    cleanupHasErrors?: boolean;
    cleanupHasCompleted?: boolean;
}

export class CleanupAppProgress extends StepSyncProgress {
    public cleanupHasStarted: boolean = false;
    public cleanupHasErrors: boolean = false;
    public cleanupHasCompleted: boolean = false;

    get progressStatusPending(): number {
        return this.cleanupHasStarted && !this.cleanupHasCompleted ? 100 : 0;
    }

    get progressStatusSuccess(): number {
        return this.cleanupHasCompleted ? 100 : 0;
    }

    get progressStatusError(): number {
        return this.cleanupHasErrors ? 100 : 0;
    }

    constructor(options: ICleanupAppProgressOptions) {
        super(options);

        options.cleanupHasStarted && (this.cleanupHasStarted = options.cleanupHasStarted);
        options.cleanupHasErrors && (this.cleanupHasErrors = options.cleanupHasErrors);
        options.cleanupHasCompleted && (this.cleanupHasCompleted = options.cleanupHasCompleted);
    }

    public toJSON(): ICleanupAppProgressJSON {
        return Object.assign(super.toJSON(), this);
    }
}
